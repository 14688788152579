


























import Feature from '../mixins/Feature'
import mixins from 'vue-typed-mixins'
export default mixins(Feature).extend({
  name: 'Feature10'
})
